import './App.css';
import Chatbot from './Component/chatpage';
import background from './Component/backdrop.jpeg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Chatbot backgroundImage={background} />
      </header>
    </div>
  );
}

export default App;
