import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './chatpage.css';
import logo from './logo.jpeg'; // Import the logo

const ChatBot = ({ backgroundImage }) => {
  const [botState, setBotState] = useState('idle'); // idle, thinking, response
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [ratings, setRatings] = useState({});
  const chatContentRef = useRef(null);
  const inputRef = useRef(null); // Add a ref for the input element

  useEffect(() => {
    if (botState === 'response') {
      setTimeout(() => {
        setBotState('idle');
      }, 3000);
    }
  }, [botState]);

  const handleUserInput = async (event) => {
    event.preventDefault();
    const newMessage = { sender: 'user', text: userInput };
    setChatHistory((prevHistory) => [...prevHistory, newMessage]);
    setUserInput('');
    setBotState('thinking');

    try {
      const response = await axios.post('https://gpt.blocks.inc/api/python/api/generate_response', { query: userInput, chat_history: chatHistory });
      const botResponse = {
        sender: 'bot',
        text: response.data.response,
        id: response.data.id,
        queryType: response.data.type,
      };

      const sources = response.data.sources;
      const queryType = response.data.type;

      for (let i = 0; i < sources.length; i++) {
        const source = sources[i];
        const sourceMessage = {
          type: 'source',
          text: source,
        };
        console.log(sourceMessage);
      }

      if (queryType !== 'general') {
        const qa = {
          question: userInput,
          answer: response.data.response,
        };

        axios.post('https://gpt.blocks.inc/api/nodejs/qa/createQA', qa)
          .then((res) => {
            console.log('QA created successfully:', res.data);
            botResponse.id = res.data.id;  // Assign the returned ID to the bot response
          })
          .catch((error) => {
            if (error.response && error.response.status === 409) {
              console.error('Conflict error while creating QA:', error.response.data);
            } else {
              console.error('Error creating QA:', error);
            }
          });
      };

      setChatHistory((prevHistory) => [...prevHistory, botResponse]);
      setBotState('response');
    } catch (error) {
      console.error('Error fetching bot response:', error);
      const errorMessage = { sender: 'bot', text: 'There seems to be a problem generating a response. Check your connection or try again in a few moments.' };
      setChatHistory((prevHistory) => [...prevHistory, errorMessage]);
      setBotState('idle');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleUserInput(event);
  };

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [chatHistory]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus the input element when the component mounts
    }
  }, []);

  const handleStarClick = (responseId, rating, queryType) => {
    setRatings((prevRatings) => ({ ...prevRatings, [responseId]: rating }));

    if (queryType !== 'general') {
      axios.post(`https://gpt.blocks.inc/api/nodejs/qa/setRating/${responseId}`, { rating })
        .then((res) => {
          console.log('Rating set successfully:', res.data);
        })
        .catch((error) => {
          console.error('Error setting rating:', error);
        });
    }
  };

  return (
    <div className="chatbot-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="chat-window">
        <div className="chat-content" ref={chatContentRef}>
          {chatHistory.map((message, index) => (
            <div key={index} className={`message-bubble ${message.sender}`}>
              <div className="message-header">
                {message.sender === 'bot' && <img src={logo} alt="Logo" className="bot-logo" />}
                <div className="message-title">{message.sender === 'user' ? 'You' : 'DAOChatBot'}</div>
              </div>
              <div className="message-text">{message.text}</div>
              {message.sender === 'bot' && (
                <>
                  <div className="separator"></div>
                  <div className="rating">
                    <span className="rate-text">Rate this response:</span>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <span
                        key={star}
                        className={`star ${ratings[message.id] >= star ? 'filled' : ''}`}
                        onClick={() => handleStarClick(message.id, star, message.queryType)}
                      >
                        ★
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <form className="query-form" onSubmit={handleSubmit}>
          <div className="input-container">
            <div className={`reactive-fluid ${botState}`}>
              <div className="dot"></div>
            </div>
            <input
              type="text"
              className="query-input"
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              ref={inputRef} // Add the ref to the input element
            />
          </div>
          <button type="submit" className="send-button">Send</button>
        </form>
      </div>
    </div>
  );
};

export default ChatBot;